import React from "react";
import { styled, Grid, GridSize } from "@mui/material";
import GreenSwitch from "./GreenSwitch";
import Checkbox from "components/base/Checkbox";

const StyledRow = styled(Grid)`
  margin: 12px 0;
`;

const StyledLabel = styled(Grid)`
  display: flex;
  align-items: center;
`;

const InputContainer = styled(Grid)`
  max-width: 400px;
`;

interface Props {
  label: any;
  name: string;
  children: any;
  disabled?: boolean;
  toggleActive?: boolean;
  handleDisable?: any;
  readOnly?: boolean;
  showComponentOnly?: boolean;
  inBulkEdit?: boolean;
  labelXs?: any;
  errorChildren?: any;
}

const FormRow = ({
  label,
  name,
  children,
  disabled,
  toggleActive,
  handleDisable,
  readOnly = false,
  showComponentOnly,
  inBulkEdit = false,
  labelXs = 3,
  errorChildren,
}: Props) => {
  const inputXs: GridSize = (11 - labelXs) as any;
  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleDisable && handleDisable(event.target.checked);
  };
  return (
    <StyledRow item container md={8} xs={12} alignItems="center">
      {!showComponentOnly && (
        <>
          <Grid item xs={inBulkEdit ? "auto" : 1}>
            {inBulkEdit ? (
              <Checkbox
                data-test-id={`${name}-bulk-edit-checkbox`}
                checked={!disabled}
                onChange={handleToggle}
              ></Checkbox>
            ) : toggleActive ? (
              <GreenSwitch
                checked={!disabled}
                onChange={readOnly ? undefined : handleToggle}
                aria-label="login switch"
                data-test-id={`${name}-switch`}
                disabled={readOnly}
              />
            ) : null}
          </Grid>
          <StyledLabel item xs={labelXs}>
            {label}
          </StyledLabel>
        </>
      )}
      <Grid item xs={inputXs}>
        <InputContainer container>{children}</InputContainer>
      </Grid>
      {errorChildren && (
        <Grid item container md={12} xs={12} alignItems="center">
          {!showComponentOnly && <Grid item xs={inBulkEdit ? "auto" : 1} />}
          <Grid item xs={labelXs} style={{ width: "100%" }} />
          <Grid item xs={inputXs}>
            {errorChildren}
          </Grid>
        </Grid>
      )}
    </StyledRow>
  );
};

export default FormRow;
