import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import ViolationsTable from "components/PeerReview/ViolationsTable";
import { useMutation } from "@apollo/client";
import FormPage from "components/base/FormPage";
import MultiSelectField, {
  MultiSelectOption,
} from "components/formFields/MultiSelectField";
import SelectField from "components/formFields/SelectField";
import CheckboxField from "components/formFields/CheckboxField";
import {
  GET_VIOLATIONS,
  GET_PEER_REVIEW_SITES,
} from "graphql/PeerReviewQueries";
import { useQuery } from "@apollo/client";
import ViolationModal from "components/PeerReview/ViolationsModal";
import Button from "components/base/Button";

import { peerReviewContent } from "i18n/constants";

import {
  GET_CAREPLAN_TEMPLATE,
  UPDATE_TEMPLATE_FIELDS,
} from "graphql/TemplateQueries";
import { SelectOptionsInterface } from "interfaces/common";

interface Props {
  template: any;
  selectOptions: SelectOptionsInterface;
  disableEditing: boolean;
}

const PeerReview = ({ template, selectOptions, disableEditing }: Props) => {
  const defaultValues = template?.templateRules.reduce(
    (fieldValues: any, fieldDetails: any) => {
      const { field, defaultValue } = fieldDetails;
      const value = JSON.parse(defaultValue);
      if (field.name === "Always_Trigger") {
        fieldValues[field.name] = JSON.parse(value);
      } else {
        fieldValues[field.name] = value;
      }
      return fieldValues;
    },
    {},
  );
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const { heading, fields } = peerReviewContent;
  const { templateId } = useParams<{ templateId: string }>();
  const [state, setState] = useState({ violationData: null, editIndex: -1 });

  const [updateTemplateField] = useMutation(UPDATE_TEMPLATE_FIELDS, {
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
  });

  const handleTemplateFieldMutation = (fieldName: string, value: any) => {
    if (fieldName === "Always_Trigger") {
      value = value.toString();
    }
    const updateVariables = { templateId, fieldName, defaultValue: value };
    updateTemplateField({ variables: updateVariables });
  };

  const { data } = useQuery(GET_VIOLATIONS, { variables: { templateId } });
  const { data: siteList } = useQuery(GET_PEER_REVIEW_SITES, {
    variables: { templateId },
  });

  const enabledSubregionsOptions: MultiSelectOption[] =
    template?.subregions?.map((enabledSubregion: any) => {
      return { label: enabledSubregion?.name, value: enabledSubregion?.name };
    });
  const addViolation = () => {
    setState({ violationData: null, editIndex: -1 });
    setIsModalVisible(true);
  };

  const editViolation = (index: number) => {
    setState({ violationData: data?.violations[index], editIndex: index });
    setIsModalVisible(true);
  };
  return (
    <>
      <Formik
        initialValues={defaultValues}
        enableReinitialize
        onSubmit={() => {}}
      >
        {() => (
          <Form>
            <FormPage heading={heading}>
              <Field
                component={MultiSelectField}
                id="enabled-subregions-id"
                name="Enabled_Subregions"
                label={fields.Enabled_Subregions}
                options={enabledSubregionsOptions}
                handleMutation={handleTemplateFieldMutation}
                isActive={!disableEditing}
                placeholder="Subregions to enable peer review for"
              />
              <Field
                component={CheckboxField}
                id="always-trigger-id"
                name="Always_Trigger"
                label={fields.alwaysTrigger}
                handleMutation={handleTemplateFieldMutation}
                isActive={!disableEditing}
                readOnly={disableEditing}
              />
              <Field
                component={MultiSelectField}
                id="credentials-id"
                name="Credentials"
                label={fields.Credentials}
                options={selectOptions.credential}
                handleMutation={handleTemplateFieldMutation}
                isActive={!disableEditing}
                placeholder="Credentials required to use this template"
              />
              <Field
                component={SelectField}
                id="qcl-id"
                name="QCL"
                label={fields.QCL}
                options={selectOptions.qcl}
                handleMutation={handleTemplateFieldMutation}
                noEmptyOption
                isActive={!disableEditing}
              />
            </FormPage>
          </Form>
        )}
      </Formik>
      <div key="violations-table">
        <h2>Violations</h2>
        <ViolationsTable
          violations={data?.violations}
          isActive={!disableEditing}
          editViolation={editViolation}
        />
        <Button
          disabled={disableEditing}
          id="addViolation"
          onClick={() => addViolation()}
        >
          Add Violation
        </Button>
        {isModalVisible}
      </div>
      <ViolationModal
        setIsVisible={setIsModalVisible}
        isVisible={isModalVisible}
        index={state.editIndex}
        violation={state.violationData}
        templateId={templateId}
        siteList={siteList?.peerReviewSites}
      />
    </>
  );
};

export default PeerReview;
